<template>
    <div>
        <div class="dF jSB">

            <h4 class="pb-2" >{{currentUnitCategory}}</h4>
            <MoreActions v-if="selectedRowKeys.length > 1" shape="button" buttonText="Bulk Actions" :items="bulkActions" @action="bulkActioned" />

        </div>
        <div class="dF" style="justify-content:space-between; padding:18px 0">
            <div>
                <div>
                    {{cardName}}
                        <small class="ml-3" style="color:#9EA0A5">{{totalFloors}} in total</small>
                </div>
            </div>
            <div class="dF aC">
                <div>
                    Floor levels
                </div>
                <div style="color:var(--orange)">
                <a-icon style="color:var(--orange)" class="ml-3 mr-3" type="left" @click="prevFloor"/>

                <a-input readOnly @change="changeFloor" v-model="currentFloor" class="mr-3" min="1" style="color:var(--orange); width:40px" />/
                {{totalFloors}}
                <a-icon style="color:var(--orange)" class="ml-3" type="right" @click="nextFloor" />

                </div>
            </div>
        </div>
        <a-table :rowKey="e => e.id" :row-selection="{selectedRowkeys: selectedRowKeys, onChange:onSelect}" :columns="columns" :data-source="units" class="units-table" :pagination="false" :scroll="{ x: 1500, y:'calc(100vh - 444.15px)' }">
            <div slot="features" slot-scope="record">
                <div>
                    {{record.packages[0].beds || 0}} Beds
                </div>
                <div>
                    {{record.packages[0].baths || 0}} Baths
                </div>
				<div v-if="record?.packages[0]?.powderRooms > 0">
                    {{record.packages[0].powderRooms || 0}} Pwdr Room
                </div>
                <div>
                    {{record.packages[0].parking || 0}} Parking Spot
                </div>
                <div>
                    {{record.packages[0].locker || 0}} Locker
                </div>
            </div>
            <div slot="exposure" slot-scope="record">
                <div v-if="!record.packages[0].exposure">
                    N/A
                </div>
                <div v-else>
                    {{ getExposure(record.packages[0].exposure) }}
                </div>
            </div>

            <div slot="unitcode" slot-scope="record" style="cursor:pointer">
                <div @click="editUnit(record.id)">{{record.unitCode}}</div>
            </div>

            <div slot="unitnumber" slot-scope="record" style="cursor:pointer">
                <div @click="editUnit(record.id)">{{record.unitNumber}}</div>
            </div>

            <div slot="name" slot-scope="record" style="cursor:pointer">
                <div @click="editUnit(record.id)" style="font-weight:600">{{record.name}}</div>
            </div>

            <div @click="editUnit(record.id)" style="cursor:pointer" slot="floorplan" slot-scope="record" >
                <img :src="record.image" style="width:100px"/>
            </div>
            <div slot="salesStatus" slot-scope="record">
				<div
					:style="`background-color:${getStatusColor(record.salesStatus)}; color:#FFF;`"
					style="border-radius: 4px"
					class="px-2 py-1 text-center"
				>
					{{getStatusName(record.salesStatus)}}
				</div>
            </div>
            <div slot="status" slot-scope="record">
                <strong>{{record.status.charAt(0).toUpperCase()+record.status.slice(1)}}</strong>
            </div>
            <div slot="price" slot-scope="record">
                <div v-if="record.packages[0].price">{{`$${record.packages[0].price && record.packages[0].price.toLocaleString("US") || 0}`}}</div>
            </div>
            <div slot="action" slot-scope="record" class="dF jE">
                <a-popover trigger="click" placement="bottomRight" overlayClassName="popoverStyle">
                    <div slot="content">
                        <div @click="action({key:'preview', val:record})" class="popoverContent dF aC"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye mr-2"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg><div>Preview</div></div>
                        <div class="popoverContent dF aC" @click="action({key:'edit', val:record})"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>Edit</div>
                        <div @click="action({key:'duplicate', val:record})" class="popoverContent dF aC"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-square mr-2"><polyline points="9 11 12 14 22 4"></polyline><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path></svg><div>Duplicate</div></div>
                        <div @click="action({key:'delete', val:record})" class="popoverContent dF aC"><svg style="color:#FD647C" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2 mr-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg><div>Delete</div></div>
                    </div>
                    <div class="more-option-icon mr-4">
                        <a-icon style="line-height: 40px;" type="more" />
                    </div>
                </a-popover>
            </div>
        </a-table>
        <a-modal :visible="visible" :title="null" :footer="null" @cancel="closeModal" centered>
            <h5>{{title}}</h5>
            <p>{{message}}</p>
            <a-select v-if="title === 'Change Sales Status'" v-model="newSalesStatus" style="width:100%">
                <a-select-option v-for="stat in salesStatus" :key="stat.value">{{stat.label}}</a-select-option>
            </a-select>
            <a-select v-else v-model="newPublishStatus" style="width:100%">
                <a-select-option v-for="stat in status" :key="stat.value">{{stat.label}}</a-select-option>
            </a-select>
            <div style="text-align:end">
                <a-button style="width:120px" type="primary" class="mt-5 mr-3" @click="confirm">CONFIRM</a-button>
                <a-button style="width:120px" class="mt-5" @click="visible =! visible">CANCEL</a-button>
            </div>
        </a-modal>
    </div>
</template>

<script>
import MoreActions from 'bh-mod/components/common/MoreActions'
export default {
     props:{
        units:{
            type:Array,
            default:() => []
        },
    },

    components:{MoreActions},
    data(){
        return {
            newSalesStatus:'',
            newPublishStatus:'',
            salesStatus:[
                {label:'Available', value:'available'},
                {label:'Unavailable', value:'unavailable'},
                {label:'Hold', value:'hold'},
                {label:'Sold', value:'sold'}
            ],
            status:[
                {label:'Published', value:'published'},
                {label:'Draft', value:'draft'}
            ],
            visible:false,
            message:'',
            title:'',
            bulkActions:[
                {
                    label:'Change Publish Status',
                    value:'changePublishStatus'
                },
                {
                    label:'Delete Selected Units',
                    value:'delete'
                }
            ],
            selectedRowKeys:[],
            selectedRows:[],
            unitActions:[
                {
                    label:'Preview',
                    value:'preview',
                    icon:'fe-eye',
                },
                {
                    label:'Edit',
                    value:'edit',
                    icon:'fa-pencil',
                },
                {
                    label:'Duplicate',
                    value:'duplicate',
                    icon:'fe-copy'
                },
                {
                    label:'Delete',
                    value:'delete',
                    icon:'fe-trash'
                },
            ],
            columns: [
                {
                    title: 'Floor Plan',
                    key: 'floorplan',
                    scopedSlots: { customRender: 'floorplan' },
                },

                {
                    title: 'Unit No.',
                    key: 'unitnumber',
                    scopedSlots: { customRender: 'unitnumber' },
					sorter: (a, b) => a.unitNumber - b.unitNumber
                },

                {
                    title: 'Unit Code',
                    key: 'unitcode',
                    scopedSlots: { customRender: 'unitcode' },
					sorter: (a, b) =>
                        a.unitCode < b.unitCode
                            ? -1
                            : a.unitCode > b.unitCode
                            ? 1
                            : 0,
                },

                {
                    title: 'Marketing Name',
                    key: 'name',
                    scopedSlots: { customRender: 'name' },
					sorter: (a, b) =>
                        a.name < b.name
                            ? -1
                            : a.name > b.name
                            ? 1
                            : 0,
                },

                {
                    title: 'Floor Level',
                    dataIndex: 'unitGroup.name',
                },
                {
                    title: 'Legal Level',
                    dataIndex: 'legalLevel',
                },

                {
                    title: 'Area Size',
                    dataIndex: 'packages[0].sqft',
					sorter: (a, b) => a.packages[0].sqft - b.packages[0].sqft

                },

                {
                    title: 'Exposure',
                    key: 'exposure',
                    scopedSlots: { customRender: 'exposure' },
					sorter: (a, b) =>
                        a.packages[0].exposure < b.packages[0].exposure
                            ? -1
                            : a.packages[0].exposure > b.packages[0].exposure
                            ? 1
                            : 0,
                },

                {
                    title: 'Features',
                    key: 'features',
                    scopedSlots: { customRender: 'features' },
                },

                {
                    title: 'Sales Status',
                    key: 'salesStatus',
                    scopedSlots: { customRender: 'salesStatus' },
                    filters:[
                        { value: "available", text: "Available" },
						{ value: "sold", text: "Sold" },
						{ value: "hold", text: "Hold" },
						{ value: "conditional", text: "Conditional" },
						{ value: "inventory", text: "Inventory" },
						{ value: "notreleased", text: "Not Released" },
                    ],
                    onFilter: (value, record) => record.salesStatus === value,
                    sorter: (a, b) => {
                        return a.salesStatus.length - b.salesStatus.length
                    },
					width: '150px'
                },

                {
                    title: 'Price',
                    key: 'price',
                    scopedSlots: { customRender: 'price' },
					sorter: (a, b) => (a.packages[0].price || 0) - (b.packages[0].price || 0)
                },

                {
                    title: 'Visibility',
                    key: 'status',
                    scopedSlots: { customRender: 'status' },
                    filters:[
                        {text:'Published', value:'published'},
                        {text:'Draft', value:'draft'},
                    ],
                    onFilter: (value, record) => record.status === value,
                },


                {
                    title: '',
                    key: 'action',
                    scopedSlots: { customRender: 'action' },
                },

            ]
        }
    },
    computed: {
        storeUnits() {
            return this.$store.state.condoUnits.units
        },
        currentFloor() {
            let current = this.$store.state.condoUnits.currentFloor
            return current
        },
        cardName() {
            return this.$store.getters.cardName
        },
        totalFloors() {
            return this.$store.state.condoUnits.floors.length
        },
        instance(){
          return this.$store.state.instance
        },
        currentUnitCategory() {
            let unitCategory = this.$store.state.condoUnits.currentUnitCategory
            if (unitCategory == 'all') unitCategory= 'Floors and Units'
            return unitCategory
        },
        rowSelection() {
            const { selectedRowKeys } = this;
            return {
                selectedRowKeys,
                onChange: this.onSelectChange,
                hideDefaultSelections: true,
                selections: [
                    {
                        key: 'all-data',
                        text: 'Select All',
                        onSelect: () => {
                            this.selectedRowKeys = [...Array(46).keys()]; // 0...45
                        },
                    },
                ],
                onSelection: this.onSelection,

                getCheckboxProps: record => ({
                    props: {
                        disabled: record.name === 'Disabled User', // Column configuration not to be checked
                        name: record.name,
                    },
            }),
            }
        },
    },
    methods:{
		getExposure(item) {
			let exposures = {
				'none': 'N/A',
				'north': 'N',
				'south': 'S',
				'east': 'E',
				'west': 'W',
				'northEast': 'NE',
				'southEast': 'SE',
				'southWest': 'SW',
				'northwest': 'NW',
				'northEastSouth': 'NES',
				'eastSouthWest': 'ESW',
				'southWestNorth': 'SWN',
				'westNorthEast': 'WNE',
				'all': 'All',
			}
            let exposure = ''
            if (!item || item == 'none') {
                exposure = 'N/A'
            } else {
                exposure = exposures[item]
            }
            return exposure
        },
        onSelect(e) {
            this.selectedRowKeys = e
        },
        closeModal(){
            this.visible = false
            this.newSalesStatus = ''
            this.newPublishStatus = ''
        },
        editUnit(id) {
            this.$router.push(`/unitdetails/${id}`)
        },
        nextFloor () {
            let currentFloor = this.$store.state.condoUnits.currentFloor
            if (currentFloor == this.totalFloors) {
                return this.$message.error('You have reached the last floor')
            }
            else {
                currentFloor += 1
                this.$store.commit('CHANGE_FLOOR', currentFloor)
            }
        },
        prevFloor () {
            let currentFloor = this.$store.state.condoUnits.currentFloor
            if (currentFloor == 1) {
                return this.$message.error('You have reached the last floor')
            }
            else {
                currentFloor -= 1
                this.$store.commit('CHANGE_FLOOR', currentFloor)
            }

        },
        changeFloor(data) {
            console.log('data', data.data)
        },
        confirm() {
            if (this.title == 'Change Sales Status') return this.changeSalesStatus()
            else if (this.title == 'Change Publish Status') return this.changePublishStatus()
        },
        changePublishStatus() {
            let units = Object.values(this.storeUnits)
            this.selectedRowKeys.forEach(id => {
                units.forEach(unit => {
                    if (id == unit.id) {
                        unit.status = this.newPublishStatus
                        let sendObj =JSON.parse(JSON.stringify(unit))
                        this.$api.put(`/units/${this.instance.id}/${unit.id}?type=condounits`, sendObj).then( ({data}) => {
                            this.newPublishStatus = ''
                            this.visible = false
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})

                    }
                })
            })
        },
        changeSalesStatus() {

            let units = Object.values(this.storeUnits)
            this.selectedRowKeys.forEach(id => {
                units.forEach(unit => {
                    if (id == unit.id) {
                        unit.salesStatus = this.newSalesStatus
                        let sendObj =JSON.parse(JSON.stringify(unit))
                        this.$api.put(`/units/${this.instance.id}/${unit.id}?type=condounits`, sendObj).then( ({data}) => {
                            this.newSalesStatus = ''
                            this.visible = false
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})

                    }
                })
            })

        },

        async bulkActioned({key,keyPath,val}){
            let self = this

            if (key == 'delete') {
                if (this.$p < 40) return this.$message.error('You do not have permission to delete units')
                this.$confirm({
                title: "Delete",
                content: h => <div>Do you want to delete this unit?</div>,
                okText: 'Delete',
                okType: 'danger',
                cancelText: 'Cancel',
                centered: true,
                onOk() {
                    for (var i=0; i<self.selectedRowKeys.length; i++) {
                        let id = self.selectedRowKeys[i]
                        self.$api.delete(`/units/${self.instance.id}/${self.selectedRowKeys[i]}`, {buildingType:'condounits'}).then( ({data}) => {

                        self.$store.commit('DELETE_UNIT', data.id)
                        }).catch( err => {
                            console.error('errror', err)
                        })
                    }
                }
                })
            }
            if (key == 'changeSalesStatus') {
                this.visible = true
                this.title = 'Change Sales Status'
                this.message = 'Select a new Sales Status for your units'
            }
            if (key == 'changePublishStatus') {
                this.visible = true
                this.title = 'Change Publish Status'
                this.message = 'Select a new Publish Status for your units'
            }
        },
        async action(e){
            if (e.key === 'preview'){
                this.$emit('previewImage', e.val.id)
            }
            else if (e.key == 'edit') {
                this.$router.push(`/unitdetails/${e.val.id}`)
            }
            else if (e.key == 'delete') {
                if (this.$p < 40) return this.$message.error('You do not have permission to delete units')
                let self = this
                this.$confirm({
                title: "Delete",
                content: h => <div>Do you want to delete this unit?</div>,
                okText: 'Delete',
                okType: 'danger',
                cancelText: 'Cancel',
                centered: true,
                onOk() {
                    self.$emit('deleteUnit', e.val.id)
                    let index = self.selectedRowKeys.findIndex (x => x == e.val.id)
                    if (index != -1) {
                        self.selectedRowKeys.splice(index,1)
                    }
                }
                })
            }
            else if (e.key == 'duplicate') {
                this.$emit('duplicateUnit', e.val.id)
            }
        },
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },

		getStatusColor(status){
			let color = ''
			switch(status) {
				case 'available': color = '#1EC48C'
				break;
				case 'sold': color = '#FD6C83'
				break;
				case 'hold': color = '#FECE5B'
				break;
				case 'conditional': color = '#9693E8'
				break;
				case 'soldConditional': color = '#9693E8'
				break;
				case 'inventory': color = '#3395c6'
				break;
				case 'notreleased': color = '#3F3356'
				break;
				case 'allocated': color = 'var(--primary)'
				break;
				case 'approved_for_reservation': color = '#134F5C'
				break;
				case 'reserved': color = '#419E00'
				break;
			}
			return color
		},

		getStatusName(status){
			let name = ''
			switch(status) {
				case 'available': name = 'AVAILABLE'
				break;
				case 'sold': name = 'SOLD'
				break;
				case 'hold': name = 'HOLD'
				break;
				case 'conditional': name = 'CONDITIONAL'
				break;
				case 'soldConditional': name = 'CONDITIONAL'
				break;
				case 'inventory': name = 'INVENTORY'
				break;
				case 'notreleased': name = 'NOT RELEASED'
				break;
				case 'allocated': name = 'ALLOCATED'
				break;
				case 'approved_for_reservation': name = 'APPROVE FOR RESERVATION'
				break;
				case 'reserved': name = 'RESERVED'
				break;
			}
			return name
		}
    }
}
</script>
<style lang="scss" scoped>
    .popoverContent{
        height: 35px;
        width: 150px;
        line-height: 35px;
        padding-left: 10px;
    }
    .popoverContent:hover{
        background-color: var(--off-white-light);
        cursor: pointer;
    }
    .more-option-icon{
        width: 35px;
        height: 35px;
        border-radius: 35px;
        text-align: center;
        background-color: transparent;
    }
    .more-option-icon:hover{
        color: var(--orange) !important;
        cursor: pointer;
        background-color: var(--light-gray);
	}
</style>
<style lang="scss">
    .popoverStyle .ant-popover-inner-content {
        padding: 0;
        background-color: white;
        border-radius: 20px;
    }
    .units-table .ant-table-body::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    .units-table .ant-table-body::-webkit-scrollbar-track {
        background: #F2F2F2;
    }

    .units-table .ant-table-body::-webkit-scrollbar-thumb {
        background: var(--grey-purple);;
        border-radius: 10px;
    }

    .units-table .ant-table-body::-webkit-scrollbar-thumb:hover {
        background: var(--orange);
    }
</style>
